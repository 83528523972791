<template>
  <div class="tools-counter">
    <BaseIcon :type="'listing'" />
    <span>{{ count }} {{ $t('message.common.tools.results') }}</span>
  </div>
</template>

<script>
import BaseIcon from '@base/BaseIcon'

export default {
  name: 'TheToolsCounter',
  props: {
    count: {
      type: [Number, String],
      required: true
    }
  },
  components: {
    BaseIcon
  }
}
</script>

<style lang="scss">
.tools-counter{
  font-size: 1.4rem;
  line-height: 1;

  .icon{
    font-size: 1.2rem;
    color: colors('primary');
    margin-right: layout("spacing")/2;
  }
}
</style>
