<template>
    <div class="card-page-details">
      <BaseTitle class="card-page__title" tag="h3">
        <a :href="content.url">{{ content.title }}</a>
        <ElementTag class="is-yellow" v-if="content.size" :text="content.size" />
      </BaseTitle>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseTitle from '@base/BaseTitle'
import ElementTag from '@elements/ElementTag'
export default {
  name: 'CardDocumentDetails',
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  components: {
    BaseTitle,
    ElementTag
  },
  data () {
    return {
      showInfos: false
    }
  },
  computed: {
    ...mapGetters({
      device: 'breakpoints/getDevice'
    }),
    icon () {
      return !this.showInfos ? 'chevron-down' : 'chevron-up'
    },
    isDesktop () {
      return this.device === 'desktop'
    }
  },
  methods: {
    handleClick () {
      this.showInfos = !this.showInfos
    }
  }
}
</script>

<style lang="scss">
  .card-page-details{
    font-size: 1.4rem;

    // Card Document SMALL

    .card-page--small &{
      .card-page__title a{
        font-size: 1.6rem;
      }
      .tag{
        display: none;
      }
      a{
        font-size: 1.3rem;
      }
    }

  }
</style>
