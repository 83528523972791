<template>
  <div class="myspace-view">
    <TheSidebarHeader :title="$t('message.common.sidebar.title')" />
    <HubSidebar name="documents" :content="documents" />
    <HubSidebar name="events" :content="events" />
    <HubSidebar name="workgroups" :content="workgroups" />
    <HubSidebar name="holidays" :content="holidays" />
    <HubSidebar name="travels" :content="travels" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheSidebarHeader from '@blocks/sidebar/TheSidebarHeader'
import HubSidebar from '@blocks/hubs/HubSidebar'
import updateBreadcrumb from '@mixins/updateBreadcrumb'

export default {
  name: 'MySpaceView',
  components: {
    TheSidebarHeader,
    HubSidebar
  },
  mixins: [ updateBreadcrumb ],
  computed: {
    ...mapGetters({
      isVisible: 'myspace/getIsVisible',
      documents: 'myspace/getDocuments',
      events: 'myspace/getEvents',
      workgroups: 'myspace/getWorkgroups',
      holidays: 'myspace/getHolidays',
      travels: 'myspace/getTravels'
    })
  }
}
</script>

<style lang="scss">
  .myspace-view{
    .card-document{
      background-color: transparent;
    }
  }
</style>
