<template>
  <div class="group-list-item">
    <router-link :to="routePath" >
      <div
        class="group-list-item__name"
        v-text="content.text"
      />
      <BaseIcon type="chevron-right"/>
    </router-link>
  </div>
</template>

<script>
import BaseIcon from '@base/BaseIcon'

export default {
  name: 'GroupListItem',
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  components: {
    BaseIcon
  },
  computed: {
    routePath () {
      return `/contacts/groups-teams/details/${this.content.id}`
    }
  }
}
</script>

<style lang="scss">
  .group-list-item{
    @media print {
      padding-bottom: 0;
    }
    width: 100%;

    > a {
      @include add-transition;
      @media print {
        background-color: lightgray;
      }
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      background-color: shade(colors(primary), 20%);
      color: colors("background");
      border-radius:.4rem;
      transition: .3s ease-in-out all;

      &:hover {
        background-color: colors("background");
        color: colors(primary);
      }
    }
  }
</style>
