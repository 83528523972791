<template>
  <ContainerHub class="hub-search-results">
    <template slot="header">
      <HubHeader
        :titleTag="'h2'"
        :showTag="total"
        :title="title" />
    </template>

    <template slot="body">
      <div class="hub-search-results__item" v-if="hasTable">
        <ElementTable>
          <template slot="header">
            <UserTableHeader :has-checkbox="true" />
          </template>
          <template slot="body">
            <component
              :has-checkbox="true"
              :is="config.componentName"
              :display="config.displayType"
              :content="item"
              :key="`${config.componentName}-${index}`"
              v-for="(item, index) in items"
            />
          </template>
        </ElementTable>
      </div>

      <div class="hub-search-results__item" v-else>
        <component
          :is="config.componentName"
          :display="config.displayType"
          :content="item"
          :key="`${config.componentName}-${index}`"
          v-for="(item, index) in items"
        />
      </div>
    </template>

    <template slot="footer">
      <BaseButton
        :icon="'more'"
        :icon-position="'right'"
        :color="name"
        v-if="showLoadmore"
        @click.native="handleLoadmore"
      >
        {{ $t(`message.search.${tradName}.button.text`) }}
      </BaseButton>
    </template>

  </ContainerHub>
</template>

<script>
import BaseButton from '@base/BaseButton'
import ContainerHub from '@containers/ContainerHub'
import CardDocument from '@cards/document/CardDocument'
import CardEvent from '@cards/event/CardEvent'
import CardPage from '@cards/page/CardPage'
import GroupListItem from '@blocks/groups/GroupListItem'
import HubHeader from './HubHeader'
import ElementTable from '@elements/ElementTable'
import UserTableHeader from '@blocks/user/UserTableHeader'
import UserTableBody from '@blocks/user/UserTableBody'

export default {
  name: 'HubSearchResults',
  props: {
    name: {
      type: String,
      required: true
    },
    tradName: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    total: {
      type: [String, Number],
      required: false
    },
    items: {
      type: Array,
      required: true
    },
    hasTable: {
      type: Boolean,
      required: false
    }
  },
  components: {
    BaseButton,
    ContainerHub,
    HubHeader,
    CardDocument,
    CardEvent,
    CardPage,
    GroupListItem,
    ElementTable,
    UserTableHeader,
    UserTableBody
  },
  computed: {
    config () {
      const obj = {}
      switch (this.name) {
        case 'documents':
          obj.offsetKey = 'documents/getOffset'
          obj.totalKey = 'documents/getTotal'
          obj.componentName = 'CardDocument'
          obj.displayType = 'medium'
          obj.fetchMethod = 'page/fetchGetAddDocuments'
          break
        case 'events':
          if (this.tradName === 'upcomingEvents') {
            obj.offsetKey = 'events/getUpcomingOffset'
            obj.totalKey = 'events/getUpcomingTotal'
            obj.fetchMethod = 'page/fetchGetAddUpcomingEvents'
          } else if (this.tradName === 'pastEvents') {
            obj.offsetKey = 'events/getPastOffset'
            obj.totalKey = 'events/getPastTotal'
            obj.fetchMethod = 'page/fetchGetAddPastEvents'
          }
          obj.componentName = 'CardEvent'
          obj.displayType = 'medium'
          break
        case 'groups':
          obj.offsetKey = 'groups/getOffset'
          obj.totalKey = 'groups/getTotal'
          obj.componentName = 'GroupListItem'
          obj.displayType = 'large'
          obj.fetchMethod = ''
          break
        case 'users':
          obj.offsetKey = 'users/getOffset'
          obj.totalKey = 'users/getTotal'
          obj.componentName = 'UserTableBody'
          obj.displayType = 'large'
          obj.fetchMethod = 'page/fetchGetAddUsers'
          break
        case 'pages':
          obj.offsetKey = 'pages/getOffset'
          obj.totalKey = 'pages/getTotal'
          obj.componentName = 'CardPage'
          obj.displayType = 'large'
          obj.fetchMethod = 'page/fetchGetAddPages'
          break
        default:
          obj.offsetKey = ''
          obj.totalKey = ''
          obj.componentName = 'div'
          obj.displayType = 'large'
          obj.fetchMethod = ''
      }
      return obj
    },

    showLoadmore () {
      return this.$store.getters[this.config.offsetKey] <
      this.$store.getters[this.config.totalKey]
    }
  },
  methods: {
    handleLoadmore () {
      this.$store.dispatch(this.config.fetchMethod)
    }
  }
}
</script>

<style lang="scss">
  .hub-search-results{
    &.container-hub{
      @include media("small-medium"){
        padding: layout("spacing")/2;
      }
      @include media("large"){
        padding: layout("spacing")/2;
      }
    }

    // Card document

    .card-document{
      @include media('large-only') {
        margin-bottom: layout('spacing');
        width: 100%;
      }
      @include media('xlarge') {
        margin-bottom: layout('spacing');
      }
    }

    // Card Event

    .card-event{
      @include media("small") {
        width: 100%;
      }
      @include media("medium"){
        min-width: calc((100% - #{layout("spacing")} * 2) / 2);
      }
      @include media("large-only"){
        min-width: calc((100% - #{layout("spacing")} * 2) / 2);
        &:not(:nth-of-type(2n)) {
          margin-right: layout("spacing")/2;
        }
      }
      @include media("xlarge"){
        min-width: calc((100% - #{layout("spacing")} * 3) / 3);
        &:not(:nth-of-type(3n)) {
          margin-right: layout("spacing")/2;
        }
      }

      width: auto;
      max-width: 100%;
      flex: 1 1 auto;
      margin-left: layout("spacing")/2;
      margin-right: layout("spacing")/2;
    }

    // PAGES

    &--pages{
      .container-hub__body > div{
        @include media("large"){
          width: 100%;
          > div{
            align-items: center;
          }
        }
      }
    }

    // GROUPS

    &--groups{
      .container-hub__body > div{
        @include media("large"){
          width: 100%;
        }
      }
    }

    // Item

    &__item{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      > div{
        @include media("small-medium"){
          margin-bottom: layout("spacing");
          width: 100%;
        }
        @include media("large"){
          width: calc(50% - 1rem);
          margin-bottom: layout("spacing");

          .hub-search-results--users &,
          .hub-search-results--groups &,
          .hub-search-results--pages &{
            width: 100%;
          }
        }
      }
    }
  }
</style>
