<template>
  <div class="row">
    <div class="row__group row__group--check" v-if="!hasCheckbox">
      <BaseCheckbox
        :guid="content.guid"
        :value="content.guid"
        :label="content.guid"
        :active="content.active"
        @change="handleChange"
      />
    </div>
    <div class="row__group row__group--main">
      <div class="row__label">
        <ElementIconText icon="user" :text="$t('message.contact.table.heading.basicInfo')" />
      </div>
      <div class="row__info">
        <router-link :to="routeLink" v-if="content.avatar" :style="{'background-image': 'url(' + content.avatar + ')'}" class="preview row__info__avatar preview--avatar preview--large"></router-link>
        <ElementPreview v-else class="row__info__avatar" type="avatar" />
        <div class="row__info__base">
          <router-link :to="routeLink">{{ content.firstname }} {{ content.lastname }}</router-link>
          <div class="row__info__function">{{ content.function }}</div>
        </div>
        <a href="javascript:void(0);" class="row__item__trigger" @click.prevent.stop="handleClick" v-if="!isDesktop">
          <BaseIcon :type="icon" />
        </a>
      </div>
    </div>

    <div class="row__group row__group--secondary" v-show="showInfos">
      <div class="row__item">
        <div class="row__label">
          <ElementIconText icon="workgroups" :text="$t('message.contact.table.heading.groupsRoles')" />
        </div>
        <div class="row__info">

            <template v-if="expand">
              <div v-for="(item, index) in content.workgroups.slice(0, 1)" :key="`workgroup-${index}`">
                {{ item.name }} ({{ item.role }})
              </div>

              <a href="#" class="row__info__expand" @click.prevent.stop="expand = !expand" v-if="content.workgroups.length > 1">
                <span>More</span> <BaseIcon type="chevron-down" />
              </a>
            </template>

            <template v-else>
              <div v-for="(item, index) in content.workgroups" :key="`workgroup-${index}`">
                {{ item.name }} ({{ item.role }})
              </div>

              <a href="#" class="row__info__expand" @click.prevent.stop="expand = !expand" v-if="content.workgroups.length > 1">
                <span>Close</span> <BaseIcon type="chevron-up" />
              </a>
            </template>

        </div>
      </div>

      <div class="row__item">
        <div class="row__label">
          <ElementIconText icon="briefcase" :text="$t('message.contact.table.heading.company')" />
        </div>
        <div class="row__info">{{ content.company }}</div>
      </div>

      <div class="row__item" v-if="!hasCheckbox">
        <div class="row__label">
          <ElementIconText icon="user" :text="$t('message.contact.table.heading.phone')" />
        </div>
        <div class="row__info">{{ content.phone }}</div>
      </div>

      <div class="row__item">
        <div class="row__label">
          <ElementIconText icon="mail" :text="$t('message.contact.table.heading.email')" />
        </div>
        <div class="row__info">
          <a :href="`mailto:${ content.email }`">{{ content.email }}</a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseCheckbox from '@base/BaseCheckbox'
import BaseIcon from '@base/BaseIcon'
import ElementIconText from '@elements/ElementIconText'
import ElementRow from '@elements/ElementRow'
import ElementPreview from '@elements/ElementPreview'

export default {
  name: 'UserTableBody',
  extends: ElementRow,
  components: {
    BaseIcon,
    BaseCheckbox,
    ElementIconText,
    ElementPreview
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      required: false
    },
    hasCheckbox: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      showInfos: true,
      expand: true
      // active: false
    }
  },
  computed: {
    ...mapGetters({
      device: 'breakpoints/getDevice'
    }),
    icon () {
      return !this.showInfos ? 'chevron-down' : 'chevron-up'
    },
    isDesktop () {
      return this.device === 'desktop'
    },
    routeLink () {
      return {
        name: 'Contact Details',
        params: {
          userId: this.content.guid
        }
      }
    }
  },
  watch: {
    isDesktop () {
      this.showInfos = true
    }
  },
  methods: {
    ...mapActions({
      updateItemActive: 'users/updateItemActive'
    }),
    handleClick () {
      this.showInfos = !this.showInfos
    },
    handleChange ({ active, guid }) {
      this.updateItemActive({ active, guid })
    }
  }
}
</script>

<style lang="scss">

</style>
