<template>
  <TheTools>
    <template slot="nav-left">
      <TheToolsButton
        :text="'Search'"
        :icon="'search'"
        :type="'trigger'"
        @click="handleSearch"
        v-if="isDesktop"
      />
    </template>
    <template slot="nav-right">
      <TheToolsCounter
        :count="totalResult"
      />
    </template>
    <template slot="content">
      <TheToolsDropdown
        :type="'search'"
        :search-type="'search-category'"
        :trigger="searchTrigger"
        :force-active="current === 'search'"
      />
    </template>
  </TheTools>
</template>

<script>
import { mapGetters } from 'vuex'
import TheTools from './TheTools'
import TheToolsButton from './TheToolsButton'
import TheToolsDropdown from './TheToolsDropdown'
import TheToolsCounter from './TheToolsCounter'

export default {
  name: 'TheToolsSearch',
  components: {
    TheTools,
    TheToolsButton,
    TheToolsCounter,
    TheToolsDropdown
  },
  props: {
    totalResult: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      searchTrigger: {
        text: 'Search',
        icon: 'search'
      },
      current: ''
    }
  },
  computed: {
    ...mapGetters({
      device: 'breakpoints/getDevice'
    }),
    isDesktop () {
      return this.device === 'desktop'
    }
  },
  methods: {
    handleSearch (status) {
      this.current = status ? 'search' : ''
    },
    handleExport (status) {
      this.current = status ? 'export' : ''
    },
    handleSend (status) {
      this.current = status ? 'send' : ''
    }
  }
}
</script>

<style lang="scss">
.tools-contact{

}
</style>
