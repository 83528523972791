<template>
  <div class="card-page" :class="customClasses">
    <div class="card-page__inner">
      <ElementPreview class="card-page__preview" type="document" :display="display" :text="content.fileType" />
      <div class="card-page__description">
        <CardPageDetails :content="content" />
      </div>
    </div>
  </div>
</template>

<script>
import ElementPreview from '@elements/ElementPreview'
import CardPageDetails from './CardPageDetails'

import { mapGetters } from 'vuex'

export default {
  name: 'CardPage',
  props: {
    content: {
      type: Object,
      required: true
    },
    display: {
      type: String,
      required: false,
      default: 'large'
    }
  },
  components: {
    ElementPreview,
    CardPageDetails
  },
  computed: {
    ...mapGetters({
      device: 'breakpoints/getDevice'
    }),
    isDesktop () {
      return this.device === 'desktop'
    },
    customClasses () {
      return [
        `card-page--${this.display}`
      ]
    }
  }
}
</script>

<style lang="scss">
.card-page {

  @media print {
    background-color: transparent;
    border-left: 6px solid colors("documents");
    page-break-inside: avoid;
  }
  // Card Document MEDIUM LARGE

  &--medium,
  &--large{
    @include media("small-medium") {
      padding: layout("spacing")/2;
      border-top: 6px solid colors("documents");
    }
    @include media("large") {
      margin-bottom: 2rem;
      padding: layout("spacing");
      border-left: 6px solid colors("documents");
    }
  }

  width: 100%;
  border-radius: layout("border-radius");
  background-color: colors("foreground");

  // Inner

  &__inner{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  // Preview

  &__preview{
    flex: 0 0 auto;
  }

  // Description

  &__description{
    flex: 1 1 auto;
    padding-left: layout("spacing");
    padding-bottom: layout("spacing")/2;

    .card-page--large &{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  // Title

  &__title{
    a{
      margin-right: 1rem;
    }
  }
}
</style>
