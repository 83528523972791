<template>
  <ContainerHub class="hub-search">

    <template slot="header">
      <HubHeader
        :titleTag="'h1'"
        :title="titleWithSearch"
        :email="email"
        :description="description"
      />
      <TheToolsSearch :totalResult="totalResult" />
    </template>

    <template slot="body">

      <!-- Documents -->
      <HubSearchResults
        :name="'documents'"
        :trad-name="'documents'"
        :title="documentsTitle"
        :total="documentsTotal"
        :items="documentsItems"
        class="hub-search-results--documents"
        v-if="documentsTotal > 0"
      />

      <!-- Events Upcoming-->
      <HubSearchResults
        :name="'events'"
        :trad-name="'upcomingEvents'"
        :title="eventsUpcomingTitle"
        :total="eventsUpcomingTotal"
        :items="eventsUpcomingItems"
        class="hub-search-results--events"
        v-if="eventsUpcomingTotal > 0"
      />

      <!-- Events Past-->
      <HubSearchResults
        :name="'events'"
        :trad-name="'pastEvents'"
        :title="eventsPastTitle"
        :total="eventsPastTotal"
        :items="eventsPastItems"
        class="hub-search-results--events"
        v-if="eventsPastTotal > 0"
      />

      <!-- USERS -->
      <HubSearchResults
        :name="'users'"
        :trad-name="'users'"
        :title="usersTitle"
        :total="usersTotal"
        :items="usersItems"
        class="hub-search-results--users"
        v-if="usersTotal > 0"
        :hasTable="true"
      />

      <!-- GROUPS -->
      <HubSearchResults
        :name="'groups'"
        :trad-name="'groups'"
        :title="groupsTitle"
        :total="groupsTotal"
        :items="groupsItems"
        class="hub-search-results--groups"
        v-if="groupsTotal > 0"
      />

      <!-- PAGES -->
      <HubSearchResults
        :name="'pages'"
        :trad-name="'pages'"
        :title="pagesTitle"
        :total="pagesTotal"
        :items="pagesItems"
        class="hub-search-results--pages"
        v-if="pagesTotal > 0"
      />

      <p v-if="totalResult === 0">{{ $t(`message.search.noresults`) }}</p>
    </template>

  </ContainerHub>
</template>

<script>
import { mapGetters } from 'vuex'
import ContainerHub from '@containers/ContainerHub'
import HubHeader from './HubHeader'
import TheToolsSearch from '@blocks/tools/TheToolsSearch'
import HubSearchResults from '@blocks/hubs/HubSearchResults'

export default {
  name: 'HubSearch',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  components: {
    HubHeader,
    HubSearchResults,
    TheToolsSearch,
    ContainerHub
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      title: 'page/getTitle',
      description: 'page/getDescription',
      email: 'page/getEmail',

      documentsTitle: 'documents/getTitle',
      documentsItems: 'documents/getItems',
      documentsTotal: 'documents/getTotal',

      eventsUpcomingTitle: 'events/getUpcomingTitle',
      eventsUpcomingItems: 'events/getUpcomingItems',
      eventsUpcomingTotal: 'events/getUpcomingTotal',

      eventsPastTitle: 'events/getPastTitle',
      eventsPastItems: 'events/getPastItems',
      eventsPastTotal: 'events/getPastTotal',

      usersTitle: 'users/getTitle',
      usersItems: 'users/getItems',
      usersTotal: 'users/getTotal',

      groupsTitle: 'groups/getTitle',
      groupsItems: 'groups/getListItems',
      groupsTotal: 'groups/getTotal',

      pagesTitle: 'pages/getTitle',
      pagesItems: 'pages/getItems',
      pagesTotal: 'pages/getTotal',

      search: 'filters/getSearch'
    }),
    titleWithSearch () {
      return `${this.title} ${this.search.value}`
    },
    totalResult () {
      return Number(this.documentsTotal) +
          Number(this.eventsUpcomingTotal) +
          Number(this.eventsPastTotal) +
          Number(this.usersTotal) +
          Number(this.groupsTotal) +
          Number(this.pagesTotal)
    }
  }
}
</script>

<style lang="scss">
  .hub-search{
    .hub-header{
      &__head{
        // border-bottom: 0;
      }
    }
    .tools{
      padding-top: 0;
    }
  }
</style>
