<template>
    <div class="row--header">

      <div class="row__group row__group--check" v-if="!hasCheckbox">
        <BaseCheckbox
          :guid="'users-all'"
          :label="'All'"
          :value="'all'"
          :active="allModel"
          @change="handleChange" />
      </div>

      <div class="row__group row__group--main">
        <div class="row__label">
          <a href="#" v-if="isDesktop">
            <ElementIconText icon="user" :text="$t('message.contact.table.heading.basicInfo')" />
          </a>
          <span v-else>{{ $t('message.contact.table.heading.selectAll') }}</span>
        </div>
      </div>

      <div class="row__group row__group--secondary" v-if="isDesktop">
        <div class="row__item">
          <div class="row__label">
            <a href="#">
              <ElementIconText icon="workgroups" :text="$t('message.contact.table.heading.groupsRoles')" />
            </a>
          </div>
        </div>

        <div class="row__item">
          <div class="row__label">
            <a href="javascript:void(0)">
              <ElementIconText icon="briefcase" :text="$t('message.contact.table.heading.company')" />
            </a>
          </div>
        </div>

        <div class="row__item" v-if="!hasCheckbox">
          <div class="row__label">
            <a href="javascript:void(0)">
              <ElementIconText icon="user" :text="$t('message.contact.table.heading.phone')" />
            </a>
          </div>
        </div>

        <div class="row__item">
          <div class="row__label">
            <a href="javascript:void(0)">
              <ElementIconText icon="mail" :text="$t('message.contact.table.heading.email')" />
            </a>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ElementIconText from '@elements/ElementIconText'
import BaseCheckbox from '@base/BaseCheckbox'
import ElementRow from '@elements/ElementRow'

export default {
  name: 'UserTableHeader',
  extends: ElementRow,
  components: {
    BaseCheckbox,
    ElementIconText
  },
  props: {
    hasCheckbox: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      allModel: false
    }
  },
  computed: {
    ...mapGetters({
      device: 'breakpoints/getDevice',
      items: 'users/getItems',
      selected: 'users/getSelected'
    }),
    isDesktop () {
      return this.device === 'desktop'
    }
  },
  watch: {
    selected () {
      this.allModel = this.selected.length === this.items.length
    }
  },
  methods: {
    ...mapActions({
      updateAllItemsActive: 'users/updateAllItemsActive'
    }),
    handleChange ({ active }) {
      this.allModel = active
      this.updateAllItemsActive(active)
    }
  }
}
</script>

<style lang="scss">

</style>
