<template>
  <div class="search-view">
    <HubSearch
      name="search-results"
      v-if="loaded"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import HubSearch from '@blocks/hubs/HubSearch'
import updateBreadcrumb from '@mixins/updateBreadcrumb'

export default {
  name: 'SearchView',
  mixins: [ updateBreadcrumb ],
  components: {
    HubSearch
  },
  data () {
    return {
      loaded: true
    }
  },
  computed: {
    ...mapGetters({
      search: 'filters/getSearch'
    })
  },
  watch: {
    search () {
      console.log('ici')
      this.fetchData({ route: this.$route.name, context: 'filters' })
    }
  },
  created () {
    this.fetchGetPage({ service: 'search' }).then(() => {
      this.loaded = true
    })
  },
  methods: {
    ...mapActions({
      'fetchGetPage': 'page/fetchGetPage',
      'fetchData': 'filters/fetchData'
    })
  }
}
</script>

<style lang="scss">
  .search-view{
    // .container-hub__body{
    //   padding-top: 0;
    // }
  }
</style>
